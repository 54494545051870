// Packages
import React from 'react';
import { Helmet }  from 'react-helmet';

// Data
import logo from '../images/icons/team-galo.png';

export default function HeadMetadata({title}) {
    return (
        <Helmet>
            <meta charSet="UTF-8"/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta name="description" content="Himo Studio | HIMO is a creative web studio based in Edmonton, AB. We specialize in creating a responsive and modern web design."/>
            <link rel="icon" href={logo}/>
            <title>{title} | Team Galo Jiu Jitsu</title>
        </Helmet>
    );
}