// Packages
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

// Data 
import linksData from '../../data/linksData';

// Component
import NavItem from './NavItem';

export default function Navigation() {

    let location = useLocation();
    let path = location.pathname;
    
    const [openNav, setOpenNav] = useState(false);
    const data = linksData();


    return (
        <div className='navbar'>
            <div className="nav-logo-link-wrapper">
                <Link to="/" className="nav-logo-link">
                    <div className="nav-logo-wrapper">
                        <StaticImage
                            className="nav-logo"
                            src="../../images/icons/chicken-logo-big.png"
                            placeholder="blurred"
                            alt="Team Galo Jiu Jitsu"
                        ></StaticImage>
                    </div>
                </Link>
            </div>
            <nav className={`nav-links ${openNav? 'nav-open':''}`}>
                {data.map(l =>
                    <NavItem 
                        item={l} 
                        url={path}
                        onClick={()=>setOpenNav(!openNav)}
                    ></NavItem>
                )}
            </nav>
            <div className={`nav-burger ${openNav? 'burger-active':'' }`} onClick={() => setOpenNav(!openNav)}>
                <span className="nav-burger-line line-1"></span>
                <span className="nav-burger-line line-2"></span>
                <span className="nav-burger-line line-3"></span>
            </div>
        </div>
    )
}