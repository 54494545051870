// Packages
import React from 'react';
import { Link } from 'gatsby';

export default function NavItem({item, url}) {

    let currentLink = item.link === url;

    return (
        <Link 
            to={item.link}
            className={`${currentLink ? 'active-nav-link':''} nav-link`}
        >{item.name}</Link>
    );
}