// Packages
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export default function Navigation() {

    return (
        <div className="footer content-wrapper">
            <div className="footer-brand">
                <div className="footer-logo-wrapper">
                    <StaticImage
                        className="footer-logo"
                        src="../../images/icons/chicken-logo-big.png"
                        placeholder="blurred"
                        alt="Team Galo Jiu Jitsu"
                    ></StaticImage>
                </div>
                <h2>TEAM GALO</h2>
            </div>
            <div className="footer-contacts">
                <div className="footer-contact">
                    <div className="footer-contact-icon-wrapper">
                        <StaticImage
                            className="footer-contact-icon"
                            src="../../images/icons/phone.svg"
                            placeholder="blurred"
                            alt="Team Galo Jiu Jitsu | Phone"
                        ></StaticImage>
                    </div>
                    <a 
                        href="tel:+1 (780) 484-6524" 
                        className="footer-contact-link"
                    >+1 (780) 484-6524</a>
                </div>
                <div className="footer-contact">
                    <div className="footer-contact-icon-wrapper">
                        <StaticImage
                            className="footer-contact-icon"
                            src="../../images/icons/mail.svg"
                            placeholder="blurred"
                            alt="Team Galo Jiu Jitsu | Email"
                        ></StaticImage>
                    </div>
                    <a 
                        href="mailto:teamgalobjj@gmail.com" 
                        className="footer-contact-link"
                    >teamgalobjj@gmail.com</a>
                </div>
                <div className="footer-contact">
                    <div className="footer-contact-icon-wrapper">
                        <StaticImage
                            className="footer-contact-icon"
                            src="../../images/icons/map.svg"
                            placeholder="blurred"
                            alt="Team Galo Jiu Jitsu | Location"
                        ></StaticImage>
                    </div>
                    <a 
                        href="https://www.google.ca/maps/place/Grand+Master+K.+H.+Min+Taekwondo+Dojang/@53.5387624,-113.6106913,15.77z/data=!4m5!3m4!1s0x53a02102ee198749:0x93937e77f04854b0!8m2!3d53.5394149!4d-113.6107501" 
                        className="footer-contact-link"
                        rel="nofollow"
                        target="_blank"
                    >10001 168 St NW, Edmonton, AB T5P 3W9</a>
                </div>
            </div>
            <div className="footer-socials">
                <a 
                    className="footer-social-icon-wrapper"
                    href="https://www.facebook.com/Team-Galo-Brazilian-Jiu-Jitsu-596907953990479"
                    rel="nofollow"
                    target="_blank"
                >
                    <StaticImage
                        className="footer-social-icon"
                        src="../../images/icons/facebook.svg"
                        placeholder="blurred"
                        alt="Team Galo Jiu Jitsu | Facebook"
                    ></StaticImage>
                </a>
                <a 
                    className="footer-social-icon-wrapper"
                    href="https://www.instagram.com/teamgalojiujitsu/"
                    rel="nofollow"
                    target="_blank"
                >
                    <StaticImage
                        className="footer-social-icon"
                        src="../../images/icons/instagram.svg"
                        placeholder="blurred"
                        alt="Team Galo Jiu Jitsu | Instagram"
                    ></StaticImage>
                </a>
                <a 
                    className="footer-social-icon-wrapper"
                    href="https://www.youtube.com/channel/UCg_TPXLbGf5jcKizrGl8APA"
                    rel="nofollow"
                    target="_blank"
                >
                    <StaticImage
                        className="footer-social-icon"
                        src="../../images/icons/youtube.svg"
                        placeholder="blurred"
                        alt="Team Galo Jiu Jitsu | YouTube"
                    ></StaticImage>
                </a>
            </div>
            <div className="footer-copyright">
                <p>Copyright © 2022 - Team Galo. All Rights Reserved</p>
                <a href="https://www.himostudios.com/" target="_noblank">
                    Made with &#10084;&#65039; by HIMO | Web Studio
                </a>
            </div>
        </div>
    )
}