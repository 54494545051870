import { v4 as uuidv4 } from 'uuid';

export default function linksData() {
    return [
        {
            id: uuidv4(),
            name: "HOME",
            link: "/"
        },
        {
            id: uuidv4(),
            name: "COACHES",
            link: "/coaches"
        },
        {
            id: uuidv4(),
            name: "SCHEDULE",
            link: "/schedule"
        },
        {
            id: uuidv4(),
            name: "CLASSES",
            link: "/classes"
        },
        {
            id: uuidv4(),
            name: "CONTACT",
            link: "/contact"
        },
    ]
}