// Packages
import React from 'react';

// Styles
import '../styles/style.scss';

// Components
import Navigation from './nav/Navigation';
import Footer from './footer/Footer';
import UnderConstruction from './reusables/UnderConstruction';

export default function Layout ({children}) {

    return (
        <div className='layout'>
            {/* <UnderConstruction></UnderConstruction> */}
            <Navigation></Navigation>
            {children} 
            <Footer></Footer>
        </div>
    )
}